import React, {Fragment} from 'react'
import Navigation from './Navigation'

const Header = () => {
  return (
    <Fragment>
        <Navigation />
    </Fragment>
  )
}

export default Header